<template lang="pug">
include ../../configs/template
v-card
  v-card-title
    h4.text-center {{ $t('addNewSailor') }}
  v-card-text
    v-form(@submit.prevent="saveSailor")
      h5.text-bold-600.col-12.mb-1.mt-1.text-left {{ $t('mainInfo') }}:
      div.row
        div.col-sm-12.col-md-6.col-lg-4
          +field-validation('body.last_name_ukr', 'lastNameUK', '["required", "maxLength", "alphaUA"]')
        div.col-sm-12.col-md-6.col-lg-4
          +field-validation('body.first_name_ukr', 'firstNameUK', '["required", "maxLength", "alphaUA"]')
        div.col-sm-12.col-md-6.col-lg-4
          +field-validation('body.middle_name_ukr', 'middleNameUK', '["maxLength", "alphaUA"]')
        div.col-sm-12.col-md-6.col-lg-6
          +field-validation('body.last_name_eng', 'lastNameEN', '["required", "maxLength", "alphaEN"]')
        div.col-sm-12.col-md-6.col-lg-6
          +field-validation('body.first_name_eng', 'firstNameEN', '["required", "maxLength", "alphaEN"]')
        div.col-sm-12.col-md-6.col-lg-4
          +date-picker-validation('body.date_birth','dateBorn','dateBirthObject','["required"]')(:max="new Date().toISOString()")
        div.col-sm-12.col-md-6.col-lg-4
          +select-validation('body.sex','mappingSex','sex','nameValue','["required"]')
        div.d-flex.col-sm-12.col-md-6.col-lg-4
          +field-validation('body.tax_number','taxNumber','["required"]')(v-if="!isDontHaveInn")
          +checkbox('isDontHaveInn', 'missing').ml-3
        div.col-sm-12.col-md-6.col-lg-3.text-left
          +file-validation('sailorPhoto', 'sailorPhoto', '["maxValue"]')(placeholder="Дозволені формати JPG,JPEG,PNG")
        ViewPhotoList(v-if="sailorPhoto.length && !isLoadNewPhoto" :sailorDocument="{ photos: sailorPhoto }" documentType="sailorPhoto")

        div.col-12.text-center.pt-4
          v-btn(color="success" type="submit" :loading="buttonLoader") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { postFiles, clearBody } from '@/mixins/main'
import { addNewSailor } from '@/mixins/validationRules'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => (
  {
    first_name_ukr: null,
    first_name_eng: null,
    last_name_ukr: null,
    last_name_eng: null,
    middle_name_ukr: null,
    sex: null,
    date_birth: null,
    tax_number: null
  }
)
export default {
  name: 'AddSailor',
  data () {
    return {
      body: initBody(),
      checkAccess,
      postFiles,
      sailorPhoto: [],
      buttonLoader: false,
      isDontHaveInn: false
    }
  },
  components: { ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue') },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'home', access: checkAccess('main-addSailor') })
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      nameValue: state => state.main.lang === 'en' ? 'value_eng' : 'value_ukr',
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      mappingSex: state => state.directory.sex

    }),
    dateBirthObject () {
      return this.body.date_birth ? new Date(this.body.date_birth) : null
    }
  },
  validations () { return addNewSailor(this._data) },
  methods: {
    ...mapActions(['setNewSailorAccount']),

    async saveSailor () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.buttonLoader = true
      const data = {
        body: { ...clearBody(this.body) },
        media: {
          profile: {
            name: 'original_photo',
            file: [...this.sailorPhoto]
          }
        }
      }
      const response = await this.setNewSailorAccount(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.$router.push({ name: 'sailor', params: { id: response.data.id } })
      }
      this.buttonLoader = false
    }
  }
}

</script>

<style scoped>
.form-control.is-invalid {
  background-image: none
}
</style>
